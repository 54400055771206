<template>
    <div class="w-full mt-1">
        <div v-if="!editMode" class="w-full bg-base-100 btn" @click="onClickCreate()">
            <i class="fas fa-plus text-accent text-2xl"></i>
        </div>
        <div v-else class="w-full bg-base-100 rounded-lg">
            <div class="flex py-2 justify-between">
                <div class="flex flex-col lg:flex-row justify-between w-1/2 mx-4">
                    <input type="text" v-model="newElement.key" placeholder="Key" class="input input-ghost input-sm input-bordered mb-2 lg:mb-0">
                    <input type="text" v-model="newElement.value" placeholder="Value"  class="input input-ghost input-sm input-bordered">
                </div>
                <div class="flex items-center mx-5">
                    <ClickableIcon icon="save" @click.native="onClickSave()" />
                    <ClickableIcon icon="times" @click.native="onClickCreate()" class="mx-8 text-secondary"/>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import ClickableIcon from '@/components/ClickableIcon';
import config from '@/config';
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    components: {
        ClickableIcon
    },

    data() {
        return {
            editMode: false,
            newElement: {
                key: null,
                value: null,
            }
        }       
    },

    computed: {
        ...mapState('global', ['identity'])
    },

    methods: {
        onClickCreate() {
            this.newElement = {key:null, value:null};
            this.editMode = !this.editMode;
        },

        async onClickSave() {
            try {
                if(this.newElement.key == null || this.newElement.value == null) throw new Error('Missing information (key or value empty)');
                const response = await axios.post(config.SERVER + `/users/${this.identity.address}/information`, {
                    list: [this.newElement]
                }).then(response => response.data);
                if(response.status == 403) throw new Error(response.message)
                else {
                    this.$emit('addElement', {});
                    this.onClickCreate();
                }
            } catch(err) {
                this.$emit('addElement', {errorMessage: err.message});
            }
        }
    }
}
</script>
