<template>
    <div v-if="mounted">
        <div class="rounded-2xl shadow-xl bg-base-200 p-12">
            <div class="flex flex-col items-center mb-5">
                <i class="fas fa-lock text-6xl mb-2"></i>
                <h1 class="w-full text-3xl">Authenticate</h1>
            </div>
            <GoogleButton v-if="availableMethods.includes('110')" class="mb-3" :onSuccess="success" :onFailure="failure"/>
            <MicrosoftButton v-if="availableMethods.includes('120')" class="mb-3" :onSuccess="success" :onFailure="failure"/>
            <FIDOButton v-if="availableMethods.includes('130')" class="mb-3" :onSuccess="success" :onFailure="failure"/>
            <MetaMaskButton class="mb-3" :onSuccess="success" :onFailure="failure" />
            <div class="flex mt-8 justify-center">
                <p class="flex mr-2">See all the methods supported by the server</p>
                <div class="dropdown dropdown-hover dropdown-left">
                    <div tabindex="0" class="btn btn-circle btn-ghost btn-xs text-info">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline w-5 h-5 stroke-current">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>                          
                        </svg>
                    </div> 
                    <div tabindex="0" class="card compact dropdown-content bg-base-100 rounded-box w-64 shadow-lg px-4">
                        <div class="card-body">
                            <h2 class="card-title">Methods supported</h2>
                            <div>
                                <p v-for="elm in this.displayServerMethods" v-bind:key="elm">{{elm}}</p>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- error message -->
        <div class="alert alert-error mt-6 transition" :class="error.style">
            <div class="flex-1 items-center">
                <i class="flex fas fa-ban mx-4 stroke-current"></i>
                <label>{{ error.message }}</label>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import GoogleButton from '@/components/login/buttons/GoogleButton.vue';
import MicrosoftButton from '@/components/login/buttons/MicrosoftButton.vue';
import FIDOButton from '@/components/login/buttons/FIDOButton.vue';
import MetaMaskButton from '@/components/login/buttons/MetaMaskButton.vue';
import config from '@/config';
import router from '@/router';
import { setTokenInLocalStorage } from '@/modules/tokens';
import { setAddressInLocalStorage } from '@/modules/utils';
export default {
    components: {
        GoogleButton,
        MicrosoftButton,
        FIDOButton,
        MetaMaskButton,
    },

    data() {
        return {
            serverMethods: [],
            availableMethods: [],
            error: {
                message: null,
                style: 'opacity-0'
            },
            mounted: false,
        }
    },

    computed: {
        ...mapState('global', ['identity', 'accountAddress']),
        displayServerMethods() {
            let result = ['Metmask'];
            for(const elm of this.serverMethods) {
                switch (elm) {
                    case config.TOPICS.GOOGLE:
                        result.push('Google');
                        break;
                    case config.TOPICS.MICROSOFT:
                        result.push('Microsoft');
                        break;
                    case config.TOPICS.FIDO:
                        result.push('FIDO');
                        break;
                    case config.TOPICS.GITHUB:
                            result.push('Github');
                            break;
                    default:
                        break;
                }
            }
            return result;
        }
    },

    methods: {
        ...mapActions('global', ['setIdentity', 'setConnected']),
        async getServerAllowedMethods() {
            const methodsFromServer = await fetch(
            config.SERVER + "/auth/methods",
            {
                method: "GET",
                headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                },
            }
            ).then(async (response) => {
                return await response.json();
            });
            this.serverMethods = Object.values(methodsFromServer.methods);
        },

        getUserAllowedMethods() {
            this.serverMethods.forEach(async (method) => {
                const claimsId = await this.identity.getClaimIdsByTopic(method);
                if (claimsId.length > 0) {
                    this.availableMethods.push(method);
                }
            })
        },
        async success(tokens) {
            await setAddressInLocalStorage(this.accountAddress, this.identity.address);
            setTokenInLocalStorage('access_token', tokens.accessToken);
            setTokenInLocalStorage('refresh_token', tokens.refreshToken);
            this.setConnected(true);
            if(this.$route.query.redirect) router.push(this.$route.query.redirect);
            else router.push('/profile');
        },
        failure(message) {
            this.error.message = message;
            this.error.style = 'opacity-1';
            setTimeout(() => {
                this.error.style = 'opacity-0';
            },  5000)
            return false;
        }
    },

    async mounted() {
        await this.getServerAllowedMethods();
        await this.getUserAllowedMethods();
        this.mounted = true;
    }
}
</script>


<style scoped>
h1 {
  overflow: hidden;
  text-align: center;
}

h1:before,
h1:after {
  background-color: white;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h1:before {
  right: 0.5em;
  margin-left: -50%;
}

h1:after {
  left: 0.5em;
  margin-right: -50%;
}

</style>