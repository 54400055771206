<template>
    <div v-if="mounted" class="mx-8 md:mx-36 pt-8 grid gap-4 lg:gap-8 xl:gap-12 grid-cols-1 xl:grid-cols-5 mb-12">
        <div class="col-span-1 xl:col-span-3 bg-cover text-neutral-content card bg-base-200 shadow-xl">
            <div class="card-body mx-6">
                <div class="relative card-title mb-6">
                    <ClickableIcon v-if="!basicInformationComponent.editMode" @click.native="onClickEditBasic()" icon="pen" class="absolute right-0" />
                    <div v-else class="absolute right-0">
                        <div class="flex">
                            <ClickableIcon @click.native="onClickSaveBasic()" icon="save" class="mr-8"/>
                            <ClickableIcon @click.native="onClickEditBasic()" icon="times" class="text-secondary"/>
                        </div>
                    </div>
                    <h2>Basic Information</h2> 
                </div>
                <div class="w-full grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div v-for="(elm, idx) in basicInformation" v-bind:key="idx">
                        <h3 class="text-sm mb-1">{{basicsEnum[idx]}}</h3>
                        <p v-if="!basicInformationComponent.editMode && elm != undefined" class="text-gray-400 truncate">{{elm.value}}</p>
                        <input v-if="basicInformationComponent.editMode" type="text" v-model="basicInformationComponent.tempValues[idx]" class="input input-ghost input-sm input-bordered">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-1 xl:col-span-2 bg-cover text-neutral-content card bg-base-200 shadow-xl">
            <div class="card">
                <div class="card-body mx-6">
                    <h2 class="card-title mb-6">Identity Infromations</h2>
                    <div class="flex flex-col md:flex-row xl:flex-col">
                        <div class="flex-auto mb-6">
                            <h3 class="text-sm mb-1">Address</h3>
                            <p class="text-gray-400 truncate">{{ identity.address }}</p>
                        </div>
                        <div class="flex-auto mb-6">
                            <h3 class="text-sm mb-1">ENS Resolver</h3>
                            <p class="text-gray-400 truncate">{{ resolvedName }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="xl:col-span-5 bg-cover text-neutral-content card bg-base-200 shadow-xl">
            <div class="card-body mx-6">
                <div class="flex items-center flex-col sm:flex-row sm:justify-between card-title mb-6">
                    <h2 class="flex-auto my-6 whitespace-nowrap">Custom Information</h2>
                    <div class="flex-grow form-control w-full max-w-lg sm:ml-8">
                            <input type="text" placeholder="Search" v-model="searchBar" class="w-full input input-primary input-bordered"> 
                    </div>
                </div>
                <Table :elements="searchedValues" :searched="searchBar" @updateElement="setElement($event)" @deleteElement="deleteElement($event)"/>
                <AddCustomInformation @addElement="setElement($event)"/>
                <div class="alert alert-error mt-1 transition py-2" :class="error.style">
                    <div class="flex-1 items-center justify-center mx-4">
                        <i class="flex fas fa-ban mr-4 stroke-current"></i>
                        <label>{{ error.message }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickableIcon from '@/components/ClickableIcon';
import Table from '@/components/Table';
import AddCustomInformation from '@/components/AddCustomInformation';
import config from '@/config';

import axios from 'axios';
import { mapState } from 'vuex';

export default {
    components: {
        ClickableIcon,
        Table,
        AddCustomInformation
    },

    data() {
        return {
            mounted: false,
            basicInformationComponent: {
                editMode: false,
                tempValues: new Array(6),
            },
            basicsEnum: ['Firstname', 'Lastname', 'Gender', 'Phone Number', 'Email', 'Address'],
            basicInformation: new Array(6),
            customInformation: [],
            searchBar: null,
            resolvedName: 'Undefined',
            error: {
                message: null,
                style: 'opacity-0'
            }
        }
    },

    computed: {
        ...mapState('global', ['ENSContract', 'identity']),
        searchedValues() {
            if(this.searchBar) {
                let customResult = [];
                for(let elm of this.customInformation) {
                    if(this.searchMatch(elm)) {
                        customResult.push(elm);
                    }
                }
                return customResult;
            } else {
                return this.customInformation;
            }
        }
    },

    methods: {
        onClickEditBasic() {
            for(const [idx, elm] of this.basicInformation.entries()) {
                this.basicInformationComponent.tempValues[idx] = '';
                if(elm != undefined) {
                    this.basicInformationComponent.tempValues[idx] = elm.value;
                }
            }
            this.basicInformationComponent.editMode = !this.basicInformationComponent.editMode;
        },

        async onClickSaveBasic() {
            // isoler les élements modifiés
            let editedList = []
            for(const [idx, elm] of this.basicInformationComponent.tempValues.entries()) {
                if((this.basicInformation[idx] != undefined || elm != '') && (this.basicInformation[idx] == undefined || this.basicInformation[idx].value != elm) ) {
                    if(this.basicInformation[idx] != undefined) {
                        editedList.push({key: this.basicsEnum[idx], value: elm, id: this.basicInformation[idx].id});
                    }
                    else editedList.push({key: this.basicsEnum[idx], value: elm});
                }
            }
            await axios.post(config.SERVER + `/users/${this.identity.address}/information`, {
                list: editedList
            });
            await this.getInformation();
            this.basicInformationComponent.editMode = false;

        },

        searchMatch(information) {
            let resultType = information.key.toLowerCase().includes(this.searchBar.toLowerCase());
            let resultValue = information.value.toLowerCase().includes(this.searchBar.toLowerCase());
            return (resultType || resultValue);
        },

        async deleteElement(id) {
            await axios.post(config.SERVER + `/users/${this.identity.address}/information`, {
                list: [{id, value: ""}]
            })
            await this.getInformation();

        },

        async setElement(response) {
            if(response.errorMessage) {
                this.error.message = response.errorMessage;
                this.error.style = "";
                setTimeout(() => {
                    this.error.style = "opacity-0";
                }, 5000);
            } else {
                await this.getInformation();
            }
        },

        async getInformation() {
            let newCustomInformation = [];
            let newBasicInformation = new Array(6);
            const response = await axios.get(config.SERVER + '/users/' + this.identity.address + "/information").then( response => response.data);
            response.forEach(elm => {
                const index = this.basicsEnum.indexOf(elm.key);
                if(index != -1) newBasicInformation[index] = elm;
                else newCustomInformation.push(elm);
            });
            this.customInformation = newCustomInformation;
            this.basicInformation = newBasicInformation; 
        }
    },

    async mounted() {
        this.resolvedName = await this.ENSContract.getNameFromAddress(this.identity.address);
        await this.getInformation();
        this.mounted = true;
    }
}
</script>
