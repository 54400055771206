<template>
    <section class="flex flex-col w-1/2 min-w-lg max-w-xl mx-auto pt-8">
        <transition name="component-transition" mode="out-in">
            <Login v-if="stepper == 1" @nextStep="nextStep()"/>
            <Authenticate v-else/>
        </transition>
        <ul class="relative steps w-full min-w-xl max-w-2xl text-white" >
            <li class="step step-primary">Provide Account</li> 
            <li class="step transition-all" :class="stepper == 2 ? 'step-primary' : ''">Authenticate</li> 
        </ul>

    </section>
</template>
<script>


import Login from '@/components/login/Login.vue';
import Authenticate from '../components/login/Authenticate.vue';

export default {
    components: {
        Login,
        Authenticate,
    },

    data() {
        return {
            stepper: 1
        }
    },

    methods: {
        nextStep() {
            this.stepper = 2;
        }
    }

}
</script>

<style scoped>
.component-transition-enter-active {
  transition: all .3s ease;
}
.component-transition-leave-active {
  transition: all .3s ease;
}
.component-transition-enter {
  transform: translateX(100px);
  opacity: 0;
}

.component-transition-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
</style>