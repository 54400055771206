<template>
    <div class="overflow-x-auto rounded-lg">
        <table class="table w-full">
            <thead>
            <tr>
                <th>Key</th> 
                <th>Value</th> 
                <th></th>
            </tr>
            </thead> 
            <tbody>
            <tr v-for="elm in elements" v-bind:key="elm.id" :class="customInformationComponent.deleteMode == elm.id ? 'active' : ''">
                <td v-if="!isInEditMode(elm.id)">{{elm.key}}</td> 
                <td v-else>
                    <input type="text" v-model="customInformationComponent.tempValue.key" class="input input-ghost input-sm input-bordered">
                </td> 
                <td v-if="!isInEditMode(elm.id)">{{elm.value}}</td>
                <td v-else>
                    <input type="text" v-model="customInformationComponent.tempValue.value" class="input input-ghost input-sm input-bordered">
                </td> 
                <td>
                    <div v-if="isInEditMode(elm.id)" class="flex justify-end">
                        <ClickableIcon @click.native="onClickSaveCustom(elm.id)" icon="save" class=""/>
                        <ClickableIcon @click.native="onClickEditCustom(elm)" icon="times" class="mx-8 text-secondary"/>
                    </div>
                    <div v-else-if="customInformationComponent.deleteMode && customInformationComponent.deleteMode == elm.id" class="flex justify-end">
                        <ClickableIcon @click.native="onClickConfirmDeleteCustom(elm.id)" icon="check" class=""/>
                        <ClickableIcon @click.native="onClickDeleteCustom(elm.id)" icon="times" class="mx-8 text-secondary"/>
                    </div>
                    <div v-else class="flex justify-end">
                        <ClickableIcon @click.native="onClickEditCustom(elm)" icon="pen" class=""/>
                        <ClickableIcon @click.native="onClickDeleteCustom(elm.id)" icon="trash" class="mx-8 text-secondary"/>
                    </div>
                </td> 
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ClickableIcon from '@/components/ClickableIcon';
import config from '@/config';
import { mapState } from 'vuex';
import axios from 'axios';

export default {
    components: {
        ClickableIcon,
    },

    props: {
        elements: Array,
        searched: String,
    },

    data() {
        return {
            customInformationComponent: {
                editMode: null,
                deleteMode: null,
                tempValue: {}
            }
        }    
    },

    computed: {
        ...mapState('global', ['identity'])
    },

    methods: {
        onClickEditCustom(elm) {
            if(this.customInformationComponent.editMode != null) {
                this.customInformationComponent.editMode = null;
            } else {
                this.customInformationComponent.editMode = elm.id;
                this.customInformationComponent.tempValue = JSON.parse(JSON.stringify(elm));
            }
        },

        async onClickSaveCustom(id) {
            try {
                if(this.customInformationComponent.tempValue.key == '' || this.customInformationComponent.tempValue.value == '') throw new Error('Missing information (key or value empty)');
                const response = await axios.post(config.SERVER + `/users/${this.identity.address}/information`, {
                    list: [{ id, key: this.customInformationComponent.tempValue.key, value: this.customInformationComponent.tempValue.value }]
                }).then(response => response.data);
                if(response.status == 403) throw new Error(response.message)
                else {
                    this.customInformationComponent.editMode = null;
                    this.$emit('updateElement', {});
                }
            } catch(err) {
                this.$emit('updateElement', {errorMessage: err.message});
            }
        },

        onClickDeleteCustom(id) {
            if(this.customInformationComponent.deleteMode!= null) this.customInformationComponent.deleteMode = null;
            else this.customInformationComponent.deleteMode = id;
        },

        onClickConfirmDeleteCustom(id) {
            this.$emit('deleteElement', id);
            this.customInformationComponent.deleteMode = null;
        },


        isInEditMode(id) {
            return this.customInformationComponent.editMode != null && this.customInformationComponent.editMode == id;
        },
    }
}
</script>
