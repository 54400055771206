<template>
    <div class="hero min-h-screen -mt-4">
        <div class="flex-col hero-content text-base-content">
            <h1 class="mb-5 text-5xl font-bold">
                Regain control of your data
            </h1> 
            <p class="mb-5">
                This Web Application is a part of the Identintech project.<br/>Its purpose is to provide for the user an interface where he can edit his identity information and manage who can access them. 
            </p> 
            <router-link to="/profile" class="btn btn-primary">
                Get Started                    
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            
        }
    },
}
</script>
