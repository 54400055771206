<template>
    <div>
        <div class="rounded-2xl shadow-xl bg-base-200 text-base-content p-12">
            <div class="flex flex-col items-center">
                <i class="far fa-user-circle text-6xl mb-2"></i>
                <h1 class="w-full text-3xl">Login</h1>
            </div>           
            <input type="text" v-model="formAddress" placeholder="Identity address" class="flex w-full input input-primary input-bordered mt-8 mb-5">
            <button class="btn btn-primary w-full" @click="connect">Login</button>
        </div>
        <div class="alert alert-error mt-6 transition" :class="error.style">
            <div class="flex-1 items-center">
                <i class="flex fas fa-ban mx-4 stroke-current"></i>
                <label>{{ error.message }}</label>
            </div>
        </div>
    </div>
</template>

<script>

import { Identity, IdentitySDK } from "@onchain-id/identity-sdk";
import { mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
            formAddress: null,
            error: {
                message: null,
                style: 'opacity-0'
            }
        }
    },

    computed: {
        ...mapState('global', ['provider', 'signer', 'identity', 'ENSContract'])
    },

    methods: {
        ...mapActions('global', ['setIdentity']),
        async loadIdentity() {
            const identity = await Identity.at(this.formAddress, this.signer);
            const keys = await identity.getKeysByPurpose(
                IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT
            );
            const hashedAddress = IdentitySDK.utils.encodeAndHash(["address"], [await this.signer.getAddress()]);
            for (const key of keys) {
                if (key.key === hashedAddress) {
                    return identity;
                }
            }
            throw new Error('You are not a management key');
        },

        async connect() {
            try {
                if (!window.ethereum) {
                    throw new Error("Please install MetaMask first.");
                }
                if(this.formAddress == null || this.formAddress.length == 0) {
                    throw new Error('Please enter an identity address or ENS name');
                }
                if (!this.formAddress.startsWith("0x") || this.formAddress.length !== 42) { 
                    const response = await this.ENSContract.getAddressFromName(this.formAddress);
                    if (response === `0x${'0'.repeat(40)}`) {
                        throw new Error("Please enter a valid name or identity address");
                    }
                    this.formAddress = response;
                }
                let identity = await this.loadIdentity();
                this.setIdentity(identity);
                this.$emit('nextStep')
            } catch(err) {
                this.error.message = err.message;
                this.error.style = "";
                setTimeout(() => {
                    this.error.style = "opacity-0";
                }, 5000);
            }
        }
    }
}
</script>


<style scoped>
h1 {
  overflow: hidden;
  text-align: center;
}

h1:before,
h1:after {
  background-color: white;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h1:before {
  right: 0.5em;
  margin-left: -50%;
}

h1:after {
  left: 0.5em;
  margin-right: -50%;
}

</style>