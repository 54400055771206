<template>
	<div v-if="mounted" data-theme="dark" class="bg-base-100">
		<link rel="stylesheet" 
			href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" 
			integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" 
			crossorigin="anonymous">
		<meta name="viewport" content="width=device-width, initial-scale=1">
		<transition name="page-transition" mode="out-in">
			<router-view class="mt-20"/>
		</transition>
		<Navbar class="fixed w-full top-0"/>
	</div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import config from '@/config';

// import ONCHAINID from "@onchain-id/solidity";
import { ethers } from 'ethers';
import { Identity } from "@onchain-id/identity-sdk";
import { mapState, mapActions } from 'vuex';
import { resolveAddressFromLocalStorage } from '@/modules/utils';
export default {
	name: 'App',
	components: {
		Navbar
	},

	data() {
		return {
			mounted: false,
		}
	},

	computed: {
		...mapState('global', ['provider', 'signer', 'accountAddress', 'identity', 'connected'])
	},

	methods: {
		...mapActions('global', ['setAccountAddress', 'setProvider', 'setSigner', 'setENSContract', 'setIdentity', 'setConnected']),
		async setNetwork() {
			await window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [config['network']],
			})
			.then(() => {
				console.log('Connected to the correct network');
			})
			.catch(console.error);
		},
	},

	async mounted() {
		await this.setNetwork();
		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.setAccountAddress(accounts[0]);
        this.setProvider(new ethers.providers.Web3Provider(window.ethereum));
        this.setSigner(this.provider.getSigner());
		const abi = [
			"function getNameFromAddress(address addr) external view returns(string memory name)",
			"function getAddressFromName(string calldata name) external view returns(address addr)",
		]
		const chainId = await window.ethereum.request({ method: 'eth_chainId' });
		try {
			const ensContractAddress = config["ENS_CONTRACT"][chainId];
			this.setENSContract(new ethers.Contract(ensContractAddress, abi, this.signer));
		} catch(error) {
			console.info("No ENS available");
		}
		const identityAddress = await resolveAddressFromLocalStorage(this.accountAddress);
		if(identityAddress) {
			const identity = await Identity.at(identityAddress, this.signer);
			this.setIdentity(identity);
			this.setConnected(true);
		}
		await window.ethereum.on('accountsChanged', async () => {
			await window.location.reload();
		});
		await window.ethereum.on('chainChanged', () => {
			window.location.reload()
		})
		this.mounted = true;
	}
}
</script>

<style scoped>
.page-transition-enter-active {
  transition: all .4s ease;
}
.page-transition-leave-active {
  transition: all .2s ease;
}
.page-transition-enter, .page-transition-leave-to{
  transform: translateY(30px);
  opacity: 0;
}
</style>