<template>
    <button @click="login" type="button" class="flex btn btn-block bg-base-100 items-center">
        <div class="flex mr-4">
            <i class="fab fa-google text-xl"></i>
        </div>
        <span class="">Login from Google</span>
    </button>
</template>

<script>
import config from '@/config';
import { validateJWTGoogle } from '@/modules/validate-jwt';
import { hashStrInSha256 } from '@/modules/utils';
import { mapState } from 'vuex';
export default {
    props: {
        onSuccess: Function,
        onFailure: Function,
    },

    data() {
        return {
        }       
    },

    computed: {
        ...mapState('global', ['identity']),
    },

    methods: {

        loadScript(doc, balise, id, jsSrc, onSuccess, onError) {
            const element = doc.getElementsByTagName(balise)[0];
            const fjs = element;
            let js = element;
            js = doc.createElement(balise);
            js.id = id;
            js.src = jsSrc;
            if (fjs && fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs);
            } else {
                doc.head.appendChild(js);
            }
            js.onerror = onError;
            js.onload = onSuccess;
        },

        callbackOnSuccess() {
            const params = {
                client_id: config.GOOGLE_CLIENT_ID,
                scope: "openid",
                };

                window.gapi.load("auth2", () => {
                    const GoogleAuth = window.gapi.auth2.getAuthInstance();
                    if (!GoogleAuth) {
                        window.gapi.auth2.init(params);
                }
            });
        },

        async login() {
            if (window.gapi && window.gapi.auth2) {
                const response = await window.gapi.auth2.getAuthInstance().signIn({
                    prompt: "select_account",
                    scope: "openid",
                })
                await this.handleResponse(response);
            } else {
                this.onFailure("Try to reload the page");
            }
        },

        async handleResponse(response) {
            if (response !== null) {
                const ID_TOKEN = response.Zb.id_token;
                if (await validateJWTGoogle(ID_TOKEN, config.GOOGLE_CLIENT_ID)) {
                    const googleId = response.ya;
                    const hashGoogleId = hashStrInSha256(googleId);

                    const tokens = await fetch(
                        config.SERVER + "/auth/validation-hash",
                        {
                            method: "POST",
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                address: this.identity.address,
                                providerURL: config.network.rpcUrls[0], 
                                hash: hashGoogleId,
                                topic: config.TOPICS.GOOGLE
                            }),
                        }).then(resp => resp.json());
                    if (tokens.statusCode) {
                        this.onFailure(tokens.message); 
                    } else {
                        this.onSuccess(tokens);
                    }
                }
            } else {
                this.onFailure("Window closed by the user");
            }
        },
    },

    mounted() {
        this.loadScript(
            document,
            "script",
            "google-login",
            "https://apis.google.com/js/platform.js",
            this.callbackOnSuccess,
            (err) => {
                console.error(err);
            }
        )
    }
}
</script>
