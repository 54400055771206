import store from '@/store';
import config from '@/config';
const axios = require('axios');

import jwt_decode from 'jwt-decode';

function getTokens(type) {
    const tokensString = window.localStorage.getItem(type);
    let tokens = (tokensString == null || typeof tokensString == "undefined") ? {} : JSON.parse(tokensString);
    return tokens;
}

function isTokenValid(token) {
    if(!token) return false;
    const payload = jwt_decode(token);
    if (Date.now() >= payload.exp * 1000) { // TODO: prise en charge des fuseaux horaire ?
        return false;
    } 
    return true;
}

export async function getAccessToken() {
    const accountAddress = store.state.global.accountAddress;
    const Atoken = JSON.parse(localStorage.getItem('access_token'))[accountAddress]
    if(isTokenValid(Atoken)) {
        return Atoken;
    }
    setTokenInLocalStorage('access_token', null);
    const Rtoken = JSON.parse(localStorage.getItem('refresh_token'))[accountAddress]
    if(!isTokenValid(Rtoken)) {
        return null;
    }
    return await refreshTokens(Rtoken)
}

async function refreshTokens(Rtoken) {
    const resp = await axios.post(config.SERVER + '/auth/update', { refreshToken: Rtoken });
    const {accessToken, refreshToken } = resp.data;
    setTokenInLocalStorage('access_token', accessToken);
    setTokenInLocalStorage('refresh_token', refreshToken);
    return accessToken;
}

export function setTokenInLocalStorage(type, token) {
    const accountAddress = store.state.global.accountAddress;
    let tokens = getTokens(type);
    if(token == null)
        delete tokens[accountAddress]
    else
        tokens[accountAddress] = token;
    window.localStorage.setItem(type, JSON.stringify(tokens));
}