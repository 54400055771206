<template>
    <div class="navbar mb-2 shadow-lg bg-base-200 text-base-content rounded-b-xl">
        <div class="px-2 mx-2 navbar-start">
            <span class="text-lg font-bold">
                OwnMyData
            </span>
        </div> 
        <div class="navbar-center hidden px-2 mx-2 lg:flex">
            <div class="flex items-stretch">
                <router-link to="/" class="btn btn-ghost btn-sm rounded-btn">
                    Home
                </router-link>
                <router-link to="/profile" class="btn btn-ghost btn-sm rounded-btn">
                    Profile
                </router-link>
            </div>
        </div> 
        <div class="navbar-end">
            <router-link v-if="!connected" to="/login" class="btn rounded-btn">
                    Login
            </router-link>
            <div v-else class="btn rounded-btn" @click="logout">
                    Logout
            </div>
        </div>
    </div>
</template>


<script>
import { removeAddressFromLocalStorage } from '@/modules/utils';
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            
        }
    },

    computed: {
        ...mapState('global', ['connected', 'accountAddress']),
    },

    methods: {
        ...mapActions('global', ['setIdentity', 'setConnected']),

        async logout() {
			await removeAddressFromLocalStorage(this.accountAddress);
			this.setIdentity(null);
			this.setConnected(false);
			await window.location.reload();
		},
    }
}
</script>
