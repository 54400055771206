export default {
	namespaced: true,
	state: {
		provider: null,
		signer: null,
		accountAddress: null,
        identity: null,
		ENSContract: null,
		connected: false,
	},
	mutations: {
		setProvider(state, payload) {
			return state.provider = payload;
		},
		setSigner(state, payload) {
			return state.signer = payload;
		},
		setAccountAddress(state, payload) {
			return state.accountAddress = payload;
		},
        setIdentity(state, payload) {
			return state.identity = payload;
		},
        setENSContract(state, payload) {
			return state.ENSContract = payload;
		},
		setConnected(state, payload) {
			return state.connected = payload;
		},
	},
	actions: {
		setProvider(context, payload) {
			context.commit('setProvider', payload);
		},
		setSigner(context, payload) {
			context.commit('setSigner', payload);
		},
		setAccountAddress(context, payload) {
			context.commit('setAccountAddress', payload);
		},
        setIdentity(context, payload) {
			context.commit('setIdentity', payload);
		},
        setENSContract(context, payload) {
			context.commit('setENSContract', payload);
		},
		setConnected(context, payload) {
			context.commit('setConnected', payload);
		},
	},
	getters: {

	}
}