<template>
	<button @click="login" type="button" class="flex btn btn-block bg-base-100 items-center">
		<div class="flex mr-4">
			<i class="fas fa-th-large text-2xl"></i>
		</div>
		<span class="">Sign in with Microsoft</span>
	</button>
</template>

<script>
import config from '@/config';
import * as msal from "@azure/msal-browser";
import { validateJWTMicrosoft } from '@/modules/validate-jwt';
import { hashStrInSha256 } from '@/modules/utils';
import { mapState } from 'vuex'

export default {
	props: {
		onSuccess: Function,
		onFailure: Function,
	},

	data() {
		return {
		}       
	},

	computed: {
		...mapState('global', ['identity']),
	},

	methods: {
		async login() {
			const myMSALObj = new msal.PublicClientApplication(config['MICROSOFT_CONFIG']);
			const loginRequest = {
				scopes: ["openid"],
			};
			const response = await myMSALObj.loginPopup(loginRequest);
			if (response !== null) {
				await this.handleResponse(response);
			}
			else {
				this.onFailure("User closed the window");
			}
		},

		async handleResponse(response) {
			if (await validateJWTMicrosoft(response.idToken)) {
				const microsoftId = response.idTokenClaims.oid;
				const hashMicrosoftId = hashStrInSha256(microsoftId);

				const tokens = await fetch(
                        config.SERVER + "/auth/validation-hash",
                        {
                            method: "POST",
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                address: this.identity.address,
                                providerURL: config.network.rpcUrls[0], 
                                hash: hashMicrosoftId,
								topic: config.TOPICS.MICROSOFT
                            }),
                        }).then(resp => resp.json());
				if (tokens.statusCode) {
					this.onFailure(tokens.message); 
				} else {
					this.onSuccess(tokens);
				}
			}
		}
	}
}
</script>
