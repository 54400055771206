import Vue from 'vue'
import VueRouter from 'vue-router'

import WelcomePage from '@/views/WelcomePage'
import Profile from '@/views/Profile'
import Login from '@/views/Login'
import store from '@/store'
import { resolveAddressFromLocalStorage } from '@/modules/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    component: WelcomePage
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const accountAddress = store.state.global.accountAddress;
  if(!requiresAuth) {
    next();
    return;
  }
  if(accountAddress === null) {
    next({path: '/'});
    return;
  }  
  const authenticatedUser = await resolveAddressFromLocalStorage(accountAddress); 
  if (requiresAuth && !authenticatedUser) {
    const redirectLink = from.query.redirect ? from.query.redirect : to.fullPath;
    next({ path: '/login', query: { redirect: redirectLink } });
  }
  else next();
});

export default router