import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import axios from 'axios'
import { getAccessToken } from '@/modules/tokens';

Vue.use(VueRouter)
Vue.config.productionTip = false


axios.interceptors.request.use(async (request) => {
  if(request.url.includes('auth/'))
      return request;
  const token = await getAccessToken('access_token');
  if(token == null) {
    router.push({name: "Login"})
  }
  request.headers.Authorization = "Bearer " + token;
  return request;
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
