import base64url from "base64url";
import config from '@/config';

function publicKeyCredentialToJSON(pubKeyCred) {
	if (pubKeyCred instanceof Array) {
		let arr = [];
		for (let i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));
		return arr;
	}

	if (pubKeyCred instanceof ArrayBuffer) {
		return base64url.encode(pubKeyCred);
	}

	if (pubKeyCred instanceof Object) {
		let obj = {};

		for (let key in pubKeyCred) {
			obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
		}
		return obj;
	}
	return pubKeyCred;
}

async function sendPublicKeyCredentialAssertionToServer(publicKeyCredential, address, providerUrl) {
	const cred = publicKeyCredentialToJSON(publicKeyCredential);
	const body = JSON.stringify({
		webAuthnResponse: cred,
		address: address,
		providerURL: providerUrl,
	});
	const content = await fetch(
		config.SERVER + "/auth/verify-assertion",
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: body,
		}
	)
	.then(async response => {
		const resp = await response.json();
		return resp;
	}).catch(err => console.log("crash", err));
	return content;
}

async function generateChallengeFromServer(address) {
	return await (async () => {
		const rawResponse = await fetch(
		config.SERVER + "/auth/create",
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				address: address,
			}),
		});
		const content = await rawResponse.json();
		return content;
	})();
  }

export { sendPublicKeyCredentialAssertionToServer, generateChallengeFromServer };