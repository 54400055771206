<template>
    <button @click="login" type="button" class="flex btn btn-block bg-base-100 items-center">
        <div class="flex mr-4">
            <i class="fas fa-fingerprint text-xl"></i>
        </div>
        <span>Connect with biometrics</span>
    </button>
</template>

<script>
import config from '@/config';
import { mapState } from 'vuex';
import { sendPublicKeyCredentialAssertionToServer } from '@/modules/fido2'; 
import { getClaim } from '@/modules/claims';
import { hex2ascii } from '@/modules/utils';
import { generateChallengeFromServer } from '@/modules/fido2';
export default {
    props: {
        onSuccess: Function,
        onFailure: Function,
    },

    data() {
        return {

        }       
    },

    computed: {
        ...mapState('global', ['identity'])
    },

    methods: {
        async login() {
            const url = await this.getUrlSignaturePage()
            this.openSignInWindow(url, "ConnectWithFIDO");
        },

        async getUrlSignaturePage() {
            const claim = await getClaim(this.identity, this.identity, config.TOPICS.FIDO);
            const blockchainData = JSON.parse(hex2ascii(claim.data));
            const challenge = await generateChallengeFromServer(this.identity.address).then(response => response.challenge);
            let url = new URL("/signature", config.PORTAL);
            url.searchParams.set("address", this.identity.address);
            url.searchParams.set("credentialId", blockchainData.credentialId);
            url.searchParams.set("challenge", challenge);
            return url.href;
        },

        async generateChallengeFromServer(address) {
            return await fetch(config.SERVER + "/auth/create",
            {
                method: "POST",
                headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                address: address,
                }),
            }).then(response => response.json());
        },

        async generateChallenge() {
            const res = await this.generateChallengeFromServer(this.identity.address);
            return res.challenge;
        },

        async receiveMessage(event) {
            if (event.origin !== config.PORTAL) {
                return;
            }
            const { data } = event;
            if (data.source === "portal-ONCHAINID") {
                const { payload } = data;
                try {
                    await this.handleFIDOLogIn(payload);
                } catch(err) {
                    this.onFailure(err.message);
                }
            }
        },

        async handleFIDOLogIn(assertion) {
            const response = await sendPublicKeyCredentialAssertionToServer(
                assertion,
                this.identity.address,
                config["network"]["rpcUrls"][0]
            );
            if (response.statusCode) {
                this.onFailure(response.message);
                return;
            }
            this.onSuccess(response);
        },

        async openSignInWindow(url, name) {
            // window features
            const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";
            let windowObjectReference = null;
            if (windowObjectReference === null || windowObjectReference.closed) {
                /* if the pointer to the window object in memory does not exist
                or if such pointer exists but the window was closed */
                windowObjectReference = window.open(url, name, strWindowFeatures);
            } else if (this.previousUrl !== url) {
                /* if the resource to load is different,
                then we load it in the already opened secondary window and then
                we bring such window back on top/in front of its parent window. */
                windowObjectReference = window.open(url, name, strWindowFeatures);
                windowObjectReference.focus();
            } else {
                /* else the window reference must exist and the window
                is not closed; therefore, we can bring it back on top of any other
                window with the focus() method. There would be no need to re-create
                the window or to reload the referenced resource. */
                windowObjectReference.focus();
            }

            // add the listener for receiving a message from the popup
            window.addEventListener("message", this.receiveMessage, false);
            // assign the previous URL
            this.previousUrl = url;
        }
    },

    destroyed() {
        window.removeEventListener("message", this.receiveMessage, false);
    }
}
</script>

<style scoped>
.buttonFido {
  background-color: rgb(0, 75, 78);
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.divFido {
  border-radius: 2px;
}

.spanFido {
  font-weight: 500;
}
</style>