<template>
    <i :class="`fas fa-${icon} text-xl text-primary transform transition-transform hover:scale-110 cursor-pointer`"></i>
</template>

<script>

export default {
    props: {
        icon: {
            type: String,
            required: true
        },
    },

    data() {
        return {

        }
    },
}
</script>



